'use client';

import React, {useState} from "react";
import {Cities} from "@/utils/directus.types";
import {useEntityTranslator} from "@/utils/i18n-helpers";
import {ButtonData, SectionFilterButtons} from "./SectionFilterButtons";
import {takeRegions} from "@/utils/directus";
import {Swiper, SwiperSlide} from "swiper/react";
import style from "./CitiesSliderSection.module.sass";
import {Link} from "@/navigation";
import {cityLink} from "@/utils/links";
import {Navigation} from "swiper/modules";
import {FlexSliderSection} from "@/components/contentSections/sliderSection/FlexSliderSection.tsx";
import {FlexHeaderSection} from "@/components/contentSections/FlexHeaderSection.tsx";
import {useTranslations} from "next-intl";
import {FlexSection} from "@/components/contentSections/FlexSection.tsx";
import FallbackImage from "@/components/images/FallbackImage.tsx";

function CityCard({city, loading}: { city: Cities, loading?: "lazy" | "eager" }) {
  const et = useEntityTranslator();
  return <Link href={cityLink(city)}>
    <div className={style['cardSpace']}>
      <div className={style['cardContent']}>
        {<FallbackImage loading={loading} galleryImage={city.preview_image} width={271} height={412}/>}
        <span className={style['label']}>{et(city, 'title')}</span>
      </div>
    </div>
  </Link>
}

export function CitiesSliderSection({cities, title, filter, className, loading}: {
  cities: Cities[]
  title: string
  filter?: boolean
  className?: string
  loading?: "eager" | "lazy"
}) {
  const t = useTranslations();
  const et = useEntityTranslator();
  const world = {
    identity: 'world',
    label: t('sections.filters.world'),
  };
  const [selectedRegion, setSelectedRegion] = useState<ButtonData>(world);
  const selectableRegions = [world, ...takeRegions(cities.map(c => c.country)).map(r => ({
    identity: r.slug,
    label: et(r, 'title'),
  }))];
  const onSelect = (selected: ButtonData) => {
    setSelectedRegion(selected);
  }
  return <FlexSection className={className}>
    <FlexHeaderSection>
      {title}
    </FlexHeaderSection>
    {(filter ?? true) &&
        <SectionFilterButtons options={selectableRegions} selected={selectedRegion} onSelect={onSelect}/>}
    <FlexSliderSection namespace="cities-slider">
      <Swiper className={style['cards']} slidesPerView='auto' spaceBetween='0' centeredSlides={false}
              centeredSlidesBounds={false} modules={[Navigation]}
              navigation={{prevEl: '#prev-cities-slider', nextEl: '#next-cities-slider'}}>
        {cities.filter(c => (selectedRegion.identity && selectedRegion.identity !== 'world') ? c.country.region?.slug === selectedRegion.identity : true)
          .map((c, i) => <SwiperSlide key={c.id} className={style['card']}>
            <CityCard city={c} loading={i > 3 ? "lazy" : (loading || "eager")}/>
          </SwiperSlide>)}
      </Swiper>
    </FlexSliderSection>
  </FlexSection>
}
